import { connect } from 'react-redux'

import {
  changeType,
  nextStep,
  submitData,
  toggleApply,
  toggleBypassZip,
  toggleIncludeAddress,
  toggleField,
  toggleIncludeExisting,
} from '../../../reducers/captive'

import Existing from './existing'

export default connect((state) => state, {
  changeType,
  nextStep,
  submitData,
  toggleApply,
  toggleBypassZip,
  toggleIncludeAddress,
  toggleField,
  toggleIncludeExisting,
})(Existing)
