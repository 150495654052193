import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'classnames'

import checkmark from '../../icons/checkmark.svg'

import './info-box.scss'

const InfoBox = ({ text, classNames, error }) => {
  return (
    <div className={clsx('info-box', classNames, error && 'error')}>
      {error ? (
        <span className="times">&times;</span>
      ) : (
        <div className="checkmark">
          <img src={checkmark} alt="Checkmark" height="16" width="16" />
        </div>
      )}
      <div className="text-holder">
        <p className="text">{text}</p>
      </div>
    </div>
  )
}

InfoBox.propTypes = {
  text: PropTypes.string,
  classNames: PropTypes.string,
  error: PropTypes.bool,
}

InfoBox.defaultProps = {
  text: '',
  classNames: '',
  error: false,
}

export default InfoBox
