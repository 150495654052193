import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import clsx from 'clsx'

import InfoBox from '../../info-box'

class Existing extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit = (value) => {
    if (!this.props.singleBtn) {
      this.props.submitData({ existing: value })
    }

    if (this.props.newResearchDesign) {
      this.props.toggleField('homeOrigin', 'research-for-me')
    }

    if (this.props.toggleApplyLayout) {
      this.props.toggleApply(true)
    }

    if (this.props.toggleApplyLayout) {
      this.props.toggleApply(true)
    }

    if (this.props.landing) {
      this.props.toggleBypassZip(true)

      if (this.props.popover) {
        if (this.props.type === 'medicareAlt') {
          const data = {
            type: 'medicareAlt',
            step: 'dobmonth',
            highestStep: 'dobmonthNew',
            existing: value,
            SRC: this.props.captive.src,
            utm_campaign: this.props.captive.campaign,
            includeAddress: true,
            bypassZip: true,
            Pub_ID: this.props.captive.Pub_ID,
            Sub_ID: this.props.captive.Sub_ID,
            ...(this.props.includeCitySearch && { includeCitySearch: true }),
            ...(this.props.toggleApplyLayout && { toggleApply: true }),
            ...(this.props.captive.data.first && {
              first: this.props.captive.data.first,
            }),
            ...(this.props.captive.data.last && {
              last: this.props.captive.data.last,
            }),
            ...(this.props.captive.data.gender && {
              gender: this.props.captive.data.gender,
            }),
            ...(this.props.captive.data.address && {
              address: this.props.captive.data.address,
            }),
            ...(this.props.captive.data.zip && {
              zip: this.props.captive.data.zip,
            }),
            ...(this.props.captive.data.email && {
              email: this.props.captive.data.email,
            }),
            ...(this.props.captive.data.phone && {
              phone: this.props.captive.data.phone,
            }),
          }
          const searchParams = new URLSearchParams(data)
          this.props.toggleIncludeAddress(true)
          this.props.changeType(this.props.type)
          window.open(
            this.props.skipPrepopQuestions
              ? `/apply-d4/dobmonth/?${searchParams}`
              : `/apply-d/dobmonth/?${searchParams}`,
          )
        } else if (this.props.type === 'medicareAltOne') {
          const data = {
            short: true,
            type: 'medicareAltOne',
            step: 'dobmonthNew',
            highestStep: 'dobmonthNew',
            existing: value,
            SRC: this.props.captive.src,
            utm_campaign: this.props.captive.campaign,
            includeAddress: true,
            bypassZip: true,
            ...(this.props.includeCitySearch && { includeCitySearch: true }),
            ...(this.props.toggleApplyLayout && { toggleApply: true }),
          }
          const searchParams = new URLSearchParams(data)

          this.props.toggleIncludeAddress(true)
          this.props.changeType(this.props.type)
          window.open(`/apply/dobmonthNew/?${searchParams}`)
        } else {
          const data = {
            type: 'medicare',
            step: 'birth',
            highestStep: 'birth',
            existing: value,
            SRC: this.props.captive.src,
            utm_campaign: this.props.captive.campaign,
            bypassZip: true,
            Pub_ID: this.props.captive.Pub_ID,
            Sub_ID: this.props.captive.Sub_ID,
            ...(this.props.includeCitySearch && { includeCitySearch: true }),
            ...(this.props.toggleApplyLayout && { toggleApply: true }),
            ...(this.props.captive.data.first && {
              first: this.props.captive.data.first,
            }),
            ...(this.props.captive.data.last && {
              last: this.props.captive.data.last,
            }),
            ...(this.props.captive.data.gender && {
              gender: this.props.captive.data.gender,
            }),
            ...(this.props.captive.data.address && {
              address: this.props.captive.data.address,
            }),
            ...(this.props.captive.data.zip && {
              zip: this.props.captive.data.zip,
            }),
            ...(this.props.captive.data.email && {
              email: this.props.captive.data.email,
            }),
            ...(this.props.captive.data.phone && {
              phone: this.props.captive.data.phone,
            }),
          }
          const searchParams = new URLSearchParams(data)
          window.open(`/apply-d2/birth/?${searchParams}`)
        }

        navigate('/offers-m')
      } else {
        if (this.props.landing) {
          let path = '/apply/birth'
          this.props.toggleBypassZip(true)

          if (this.props.type === 'medicareAlt') {
            path = '/apply/dobmonth'
            if (this.props.singleBtn) {
              this.props.toggleIncludeExisting(true)
            }
            this.props.toggleIncludeAddress(true)
            this.props.changeType(this.props.type)
          }

          if (this.props.type === 'medicareAltOne') {
            path = '/apply/dobyearNew'
            this.props.toggleIncludeAddress(true)
            this.props.changeType(this.props.type)
          }
          this.props.nextStep('zip')

          navigate(path)
        }
      }
    } else {
      this.props.handleNext('existing')
    }
  }

  render = () => {
    const { landing, className, title } = this.props
    return (
      <div>
        {this.props.singleBtn ? (
          <div>
            <button
              className="btn"
              onClick={() => {
                this.handleSubmit('')
              }}
            >
              Get Quote Now!
            </button>
          </div>
        ) : (
          <>
            {!landing && (
              <InfoBox text="Great! First, just answer a few simple questions for us." />
            )}
            <strong className="h4" dangerouslySetInnerHTML={{ __html: title }} />
            <div className="row">
              <div className={clsx(className, 'col full-width inline-buttons')}>
                <button
                  className="btn"
                  onClick={() => {
                    this.handleSubmit('No')
                  }}
                >
                  No
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    this.handleSubmit('Yes')
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

Existing.propTypes = {
  landing: PropTypes.bool,
  toggleApplyLayout: PropTypes.bool,
  shouldNavigate: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  singleBtn: PropTypes.bool,
}

Existing.defaultProps = {
  landing: false,
  toggleApplyLayout: false,
  shouldNavigate: false,
  classname: '',
  title: 'Are you currently enrolled <br/> in Medicare parts A &amp; B ?',
  type: 'medicare',
  singleBtn: false,
  skipPrepopQuestions: false,
}

export default Existing
